<template>
  <section class="talk-info">
    <Heading type="h3" text-align="alter" class="talk-info__title">
      Talk information
    </Heading>
    <form @submit.prevent="submitTalkInfo" class="with-fsd__form">
      <div class="mb-4">Select track:</div>
      <div class="talk-info__tracks-wrapper mb-8">
        <div
          v-for="track in tracks"
          :key="track.id"
          class="talk-info__track"
          :class="{
            'talk-info__track--selected': track.id === form.track.value,
          }"
          @click="form.track.value = track.id"
        >
          <img
            :src="getTrackIcon(track.id)"
            :alt="`${track.title} icon`"
            class="talk-info__track-icon"
          />
          <div>
            <div>{{ track.title }}</div>
            <div class="talk-info__track-description">
              {{ track.description }}
            </div>
          </div>
        </div>
        <div v-if="form.track.error" class="talk-info__track-error">
          {{ form.track.error }}
        </div>
      </div>
      <Input
        v-model="form.title.value"
        :error="form.title.error"
        name="talk_name"
        label="Talk Name"
        type="text"
        @input="clearError('title', form)"
        @blur="validateField('title', form)"
      />
      <Input
        v-model="form.description.value"
        :error="form.description.error"
        is-multiline
        name="talk_description"
        label="Talk Description"
        @input="clearError('description', form)"
        @blur="validateField('description', form)"
      />
      <Button fullWidth class="mt-6"> next </Button>
    </form>
  </section>
</template>

<script>
import { transformForm } from "@/assets/js/utils";
import {
  validateField,
  validateForm,
  clearError,
} from "@/assets/js/validation";
import Heading from "@/components/common/Heading";
import Input from "@/components/common/Input";
import Button from "@/components/common/Button";

export default {
  name: "TalkInfo",
  props: {
    tracks: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["submit-talk-data"],
  components: {
    Heading,
    Input,
    Button,
  },
  data: () => ({
    form: {
      title: {
        value: "",
        error: "",
        rules: ["required"],
      },
      description: {
        value: "",
        error: "",
        rules: ["required"],
      },
      track: {
        value: "",
        error: "",
        rules: ["required"],
      },
    },
  }),
  computed: {},
  methods: {
    transformForm,
    validateField,
    validateForm,
    clearError,
    getTrackIcon(track) {
      if (
        track === "build_it" ||
        track === "break_it" ||
        track === "firetalks" ||
        track === "show_it"
      ) {
        return require(`../../assets/img/icons/${track}.svg`);
      } else {
        return require("../../assets/img/icons/tracks.svg");
      }
    },
    submitTalkInfo() {
      const isValid = this.validateForm(this.form);
      if (!isValid) return;

      const talkData = this.transformForm(this.form);
      const talkDisplayData = {
        name: talkData.title,
        track: this.tracks.find((track) => track.id === talkData.track).title,
        description: talkData.description,
      };

      this.$emit("submit-talk-data", [talkData, talkDisplayData]);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.talk-info {
  padding-top: 35px;

  &__title {
    margin-bottom: 54px;
  }

  &__tracks-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  &__track {
    cursor: pointer;
    border: 1px solid $dark-gray;
    padding: 15px 15px 15px 20px;
    display: flex;
    color: $black;
    font-family: $sarabun;
    font-size: 0.9rem;
    line-height: 1.3;
    text-transform: uppercase;

    &:hover {
      background-color: $light-gray;
    }

    &--selected {
      border: 1px solid $blue;
      background-color: $light-gray;
    }
  }

  &__track-description {
    font-family: $openSans;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.05em;
    color: $gray;
    text-transform: initial;
    padding-top: 3%;
  }

  &__track-icon {
    height: 16px;
    object-fit: contain;
    object-position: left;
    margin-right: 21px;
  }

  @media (min-width: $media-sm) {
    padding-top: 124px;
  }

  @media (min-width: $media-md) {
    &__tracks-wrapper {
      grid-gap: 23px;
    }
  }

  @media (min-width: $media-lg) {
    padding-top: 144px;
  }
}
</style>
